import React, { useState } from 'react';
import axios from 'axios';

const Contact = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    subject: '',
    message: ''
  });

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    try {
      await axios.post('https://strapi.sportingindia.com/api/contacts', {
        data: formData
      });
      setSuccess('Your message has been sent successfully.');
      setFormData({
        username: '',
        email: '',
        subject: '',
        message: ''
      });
    } catch (error) {
      setError('Something went wrong. Please try again.');
    }
  };

  return (
    <div className="page-content-wrapper">
      <div className="contact-form-wrapper">
        <div className="container">
          <h4 className="mb-2">Get in touch with us</h4>
          <p>Sportingindia.Tech is an IT development and consultancy firm operating at the intersection of Sports and Technology.</p>
          <h6>Call Now: +91 9876543210</h6>
          <h6 className="mb-0">Email: support@sportingindia.com</h6>

          <div className="contact-form mt-4">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  className="form-control mb-3"
                  id="yourname"
                  type="text"
                  name="username"
                  placeholder="Your Name"
                  value={formData.username}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  className="form-control mb-3"
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  className="form-control mb-3"
                  id="subject"
                  type="text"
                  name="subject"
                  placeholder="Topics"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <textarea
                  className="form-control mb-3"
                  id="message"
                  name="message"
                  cols="20"
                  rows="5"
                  placeholder="Your Message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </div>
              {error && <div className="alert alert-danger">{error}</div>}
              {success && <div className="alert alert-success">{success}</div>}
              <button className="btn btn-primary btn-lg w-100">Send Now</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
