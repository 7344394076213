import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from '../utils/axiosConfig';

const TagPage = () => {
  const { tagId } = useParams();
  const [news, setNews] = useState([]);
  const [tagName, setTagName] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalNews, setTotalNews] = useState(0);

  useEffect(() => {
    const fetchTagDetails = async () => {
      try {
        const tagResponse = await axios.get(`/tags/${tagId}`);
        setTagName(tagResponse.data.data.attributes.tag);
      } catch (error) {
        setError('Error fetching tag details');
      }
    };

    const fetchNewsByTag = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`/sportsnews?filters[tags][id][$eq]=${tagId}&populate=*&pagination[page]=${page}&pagination[pageSize]=10`);
        const newArticles = response.data.data;

        // Update state with unique articles
        setNews((prevNews) => {
          const newsMap = new Map(prevNews.map(article => [article.id, article]));
          newArticles.forEach(article => newsMap.set(article.id, article));
          return Array.from(newsMap.values());
        });

        setTotalNews(response.data.meta.pagination.total);
        setLoading(false);
      } catch (error) {
        setError('Error fetching news');
        setLoading(false);
      }
    };

    fetchTagDetails();
    fetchNewsByTag();
  }, [tagId, page]);

  const loadMoreNews = () => {
    setPage((prevPage) => prevPage + 1);
  };

  if (loading && page === 1) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="page-content-wrapper">
      <div className="trending-news-wrapper">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="mb-3 newsten-title">Trending for #{tagName}</h5>
            <p className="mb-3 line-height-1">{totalNews} Posts</p>
          </div>
        </div>

        <div className="container">
          {news.length === 0 ? (
            <div>No posts are available for this tag.</div>
          ) : (
            news.map((article) => (
              <div key={article.id} className="single-trending-post d-flex">
                <div className="post-thumbnail">
                  {article.attributes.image && article.attributes.image.data && (
                    <img
                      src={`https://strapi.sportingindia.com${article.attributes.image.data.attributes.url}`}
                      alt={article.attributes.Title}
                    />
                  )}
                </div>
                <div className="post-content">
                  <Link className="post-title" to={`/news/${article.id}`}>
                    {article.attributes.Title}
                  </Link>
                  <div className="post-meta d-flex align-items-center">
                    <Link to="#">
                      {article.attributes.category
                        ? article.attributes.category.data.attributes.Title
                        : 'Uncategorized'}
                    </Link>
                    <Link to="#">
                      {new Date(article.attributes.createdAt).toLocaleDateString()}
                    </Link>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>

        {news.length > 0 && news.length < totalNews && (
          <div className="container">
            <div className="text-center mt-3">
              <button className="btn btn-primary" onClick={loadMoreNews} disabled={loading}>
                {loading ? 'Loading...' : 'Load More'}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TagPage;
