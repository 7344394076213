import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

const SearchComponent = () => {
  const [tags, setTags] = useState([]);
  const [news, setNews] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [displayedQuery, setDisplayedQuery] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await axios.get('https://strapi.sportingindia.com/api/tags');
        setTags(response.data.data);
      } catch (error) {
        console.error('Error fetching tags:', error);
      }
    };

    const fetchNews = async () => {
      try {
        const response = await axios.get('https://strapi.sportingindia.com/api/sportsnews?populate=*');
        setNews(response.data.data.slice(0, 10));
      } catch (error) {
        console.error('Error fetching news:', error);
      }
    };

    fetchTags();
    fetchNews();
  }, []);

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(`https://strapi.sportingindia.com/api/sportnews/search?q=${searchQuery}`);
      console.log('Search results:', response.data); // Debugging: log search results
      setNews(response.data);
      setDisplayedQuery(searchQuery); // Set the displayed query
    } catch (error) {
      console.error('Error searching news:', error);
    }
  };

  const getTagColor = (index) => {
    const colors = ['btn-primary', 'btn-success', 'btn-warning', 'btn-danger', 'btn-info'];
    return colors[index % colors.length];
  };

  const renderNewsItems = (newsItems) => {
    return newsItems.map((item) => {
      console.log('Processing item:', item); // Debugging: log each item being processed

      const attributes = item.attributes || item;
      console.log('Attributes:', attributes); // Debugging: log attributes

      const imageUrl = attributes.image?.url || attributes.image?.data?.attributes?.url
        ? `https://strapi.sportingindia.com${attributes.image?.url || attributes.image?.data?.attributes?.url}`
        : 'default_image_url'; // Provide a default image URL or handle missing image case

      const title = attributes.Title || 'No Title';
      const category = attributes.category?.Title || attributes.category.data.attributes.Title || 'Uncategorized';
      const createdAt = attributes.createdAt ? new Date(attributes.createdAt).toLocaleDateString() : 'Unknown Date';

      console.log(`Title: ${title}, Category: ${category}, Image URL: ${imageUrl}, Created At: ${createdAt}`); // Debugging: log processed values

      return (
        <TrendingPost
          key={item.id}
          id={item.id}
          image={imageUrl}
          title={title}
          category={category}
          date={createdAt}
        />
      );
    });
  };

  return (
    <div className="page-content-wrapper">
      {/* Search Post Wrapper */}
      <div className="search-post-wrapper">
        {/* Shapes */}
        <div className="bg-shapes">
          <div className="shape1"></div>
          <div className="shape2"></div>
          <div className="shape3"></div>
          <div className="shape4"></div>
          <div className="shape5"></div>
        </div>

        <div className="container">
          <h4 className="mb-3 pl-2">What would <br /> you read?</h4>
          <div className="search-page-form">
            {/* Search via Voice */}
            <Link className="search-via-voice" to="#">
              <i className="lni lni-mic"></i>
            </Link>
            <form onSubmit={handleSearch}>
              <input
                className="form-control"
                type="search"
                placeholder="Title, Tags, or Author"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <button type="submit"><i className="fa fa-search"></i></button>
            </form>
          </div>
          {displayedQuery && (
            <p className="mt-2">Search results for: <strong>{displayedQuery}</strong></p>
          )}
        </div>
      </div>

      {/* Popular Tags */}
      <div className="popular-tags-wrapper">
        <div className="container">
          <h5 className="mb-3 pl-1 newsten-title">Trending Tags</h5>
        </div>

        <div className="container">
          <div className="popular-tags-list">
            {tags.map((tag, index) => (
              <Link
                key={tag.id}
                className={`btn btn-sm m-1 ${getTagColor(index)}`}
                to="#"
              >
                #{tag.attributes.tag}
              </Link>
            ))}
          </div>
        </div>
      </div>

      <div className="container">
        <div className="border-top"></div>
      </div>

      {/* Trending News or Search Results Wrapper */}
      <div className="trending-news-wrapper">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="mb-3 pl-1 newsten-title">
              {displayedQuery ? 'Search Results' : 'Popular Posts'}
            </h5>
            {!displayedQuery && (
              <Link className="btn btn-primary btn-sm mb-3" to="/news">View All</Link>
            )}
          </div>
        </div>

        <div className="container">
          {news.length === 0 ? (
            <p>{displayedQuery ? `No results found for the query: ${displayedQuery}` : 'No popular posts available'}</p>
          ) : (
            renderNewsItems(news)
          )}
        </div>
      </div>
    </div>
  );
};

const TrendingPost = ({ id, image, title, category, date }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/news/${id}`);
  };

  return (
    <div className="single-trending-post d-flex" onClick={handleClick}>
      <div className="post-thumbnail">
        <img src={image} alt="Trending" onError={(e) => { e.target.src = 'default_image_url'; }} />
      </div>
      <div className="post-content">
        <Link className="post-title" to={`/news/${id}`}>{title}</Link>
        <div className="post-meta d-flex align-items-center">
          <Link to="/category">{category}</Link>
          <Link to="#">{date}</Link>
        </div>
      </div>
    </div>
  );
};

export default SearchComponent;
