import React from 'react';
import { Link } from 'react-router-dom';
// import './Footer.css';

const Footer = () => {
  return (
    <footer>
        <div className="footer">
          <div className="container">
            <h4>Connecting <br/>Athletes, Fans, and Games</h4>
            <p className="copyrights">© 2024 All rights reserved | Sportingindia</p>
          </div>
        </div>

        {/* <!-- Footer Nav --> */}
        <div className="footer-nav-area" id="footerNav">
          <div className="newsten-footer-nav h-100">
            <ul className="h-100 d-flex align-items-center justify-content-between">
              <li className="active">
                <Link to={'/'}>
                  <i className="lni lni-home"></i>
                  <span>Home</span>
                </Link>
              </li>

              <li>
                <Link to={'/events'}>
                  <i className="lni lni-control-panel"></i>
                  <span>Events</span>
                </Link>
              </li>
              <li>
                <Link to={'/news'}>
                  <i className="lni lni-ticket-alt"></i>
                  <span>News</span>
                </Link>
              </li>
              <li>
                <Link to={'/'}>
                  <i className="lni lni-folder"></i>
                  <span>Directory</span>
                </Link>
              </li>
              <li>
                <Link to={'/more'}>
                  <i className="lni lni-more"></i>
                  <span>More</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      

    </footer>
  );
};

export default Footer;
