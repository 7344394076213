import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

const TrendingNewsList = () => {
  const [news, setNews] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchNews(page);
  }, [page]);

  const fetchNews = async (page) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`https://strapi.sportingindia.com/api/sportsnews?populate=*&pagination[page]=${page}&pagination[pageSize]=10`);
      const newNews = response.data.data;

      // Ensure there are no duplicates
      setNews(prevNews => {
        const uniqueNews = new Map();
        [...prevNews, ...newNews].forEach(item => uniqueNews.set(item.id, item));
        return Array.from(uniqueNews.values());
      });

      setTotalPages(response.data.meta.pagination.pageCount);
    } catch (error) {
      console.error('Error fetching news:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClick = (id) => {
    navigate(`/news/${id}`);
  };

  const handleLoadMore = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  return (
    <div className="page-content-wrapper">
      {/* Trending News Wrapper */}
      <div className="trending-news-wrapper">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="mb-3 newsten-title">Latest News</h5>
            <p className="mb-3 line-height-1">{news.length} Posts</p>
          </div>
        </div>

        <div className="container">
          {news.map((item) => (
            <div className="single-trending-post d-flex" key={item.id} onClick={() => handleClick(item.id)}>
              <div className="post-thumbnail">
                {item.attributes.image && item.attributes.image.data && (
                  <img src={`https://strapi.sportingindia.com${item.attributes.image.data.attributes.formats.thumbnail.url}`} alt={item.attributes.Title} />
                )}
              </div>
              <div className="post-content">
                <Link className="post-title" to={`/news/${item.id}`}>{item.attributes.Title}</Link>
                <div className="post-meta d-flex align-items-center">
                  <Link to="#">{item.attributes.category.data.attributes.Title}</Link>
                  <Link to="#">{new Date(item.attributes.createdAt).toLocaleDateString()}</Link>
                </div>
              </div>
            </div>
          ))}
        </div>

        {page < totalPages && !isLoading && (
          <div className="container">
            <div className="text-center mt-3">
              <button className="btn btn-primary" onClick={handleLoadMore}>Load More</button>
            </div>
          </div>
        )}

        {isLoading && (
          <div className="container">
            <div className="text-center mt-3">
              <p>Loading...</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TrendingNewsList;
