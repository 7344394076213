import React, { useEffect, useState } from 'react';
import axios from 'axios';

const PrivacyPolicy = () => {
  const [policyContent, setPolicyContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPrivacyPolicy = async () => {
      try {
        const response = await axios.get('https://strapi.sportingindia.com/api/privacy-policy');
        setPolicyContent(response.data.data.attributes.policy);
        setLoading(false);
      } catch (error) {
        setError('Error fetching privacy policy');
        setLoading(false);
      }
    };

    fetchPrivacyPolicy();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  const renderContent = (content) => {
    return content.map((block, index) => {
      if (block.type === 'paragraph') {
        const text = block.children.map((child, i) => {
          if (child.bold) {
            return <strong key={i}>{child.text}</strong>;
          }
          return <span key={i}>{child.text}</span>;
        });
        return <p key={index}>{text}</p>;
      }
      return null;
    });
  };

  return (
    <div className="page-content-wrapper">
      <div className="container">
        <div className="privacy-policy-wrapper">
          {renderContent(policyContent)}
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
