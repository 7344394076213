import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import axios from './utils/axiosConfig';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './components/HomePage';
import About from './components/About';
import Contact from './components/Contact';
import More from './components/More';
import Category from './components/Category';
import Login from './components/Login';
import Register from './components/Register'; 
import Profile from './components/Profile'; 
import Search from './components/SearchComponent';
import NewsDetails from './components/NewsDetailsComponent'; 
import ProtectedRoute from './components/ProtectedRoute';
import PrivacyPolicy from './components/PrivacyPolicy';
import ChangePassword from './components/ChangePassword';
import TrendingNewsList from './components/TrendingNewsList';
import TagPage from './components/TagPage';
import CategoryPage from './components/CategoryPage';
import EventsList from './components/EventsList';
import EventDetails from './components/EventDetails';
import MainForm from './components/MainEntryForm';

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      const token = localStorage.getItem('jwt');
      if (token) {
        try {
          const response = await axios.get('/users/me', {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          setUser(response.data);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
      setLoading(false);
    };

    fetchUser();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // or any loading indicator
  }

  return (
    <Router>
      <div className="App">
        <Header user={user} />
        <main>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/more" element={<More user={user} />} />
            <Route path="/category" element={<Category />} />
            <Route path="/login" element={<Login />} />
            <Route path="/search" element={<Search />} />
            <Route path="/news/:id" element={<NewsDetails />} />
            <Route path="/userregister" element={<Register />} />
            <Route path='/privacy' element={<PrivacyPolicy />} />
            <Route path='/news' element={<TrendingNewsList />} />
            <Route path='/events' element={<EventsList />} />
            <Route path='/event/:id' element={<EventDetails user={user} />} />
            <Route path='/entryregister/:id' element={<MainForm />} />
            <Route path='/category/:catId' element={<CategoryPage/>} />
            <Route path="/tag/:tagId" element={<TagPage />} />
            <Route element={<ProtectedRoute user={user} />}>
            <Route path="/changepassword" element={<ChangePassword />} />
              <Route path="/profile" element={<Profile user={user} />} />
            </Route>
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
