// src/components/MainEntryForm.js
import React from 'react';
import { useParams } from 'react-router-dom';
import Form from './Form';
// Import other form components as needed

const MainEntryForm = () => {
  const { id } = useParams(); // Ensure this matches the route parameter
  console.log('Tournament ID:', id);

  const renderForm = (tournamentId) => {
    switch (tournamentId) {
      case '1':
        console.log('Rendering Form for Tournament ID 1');
        return <Form />;
      // Add cases for other tournament IDs
      // case '2':
      //   return <Form2 />;
      // case '3':
      //   return <Form3 />;
      default:
        return <div>No form available for this tournament.</div>;
    }
  };

  return (
    <div>
      {renderForm(id)} {/* Pass the correct parameter */}
    </div>
  );
};

export default MainEntryForm;
