import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const More = ({ user }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    setIsAuthenticated(!!user);
  }, [user]);

  return (
    <div className="page-content-wrapper">
      <div className="container">
        {/* Settings Wrapper */}
        <div className="settings-wrapper">
          <div className="card settings-card">
            <div className="card-body">
              {/* Single Settings */}
              <div className="single-settings d-flex align-items-center justify-content-between">
                <div className="title">
                  <i className="lni lni-question-circle"></i>
                  <span>Support</span>
                </div>
                <div className="data-content">
                  <Link className="pl-4" to="/contact">
                    <i className="lni lni-chevron-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="card settings-card">
            <div className="card-body">
              {/* Single Settings */}
              <div className="single-settings d-flex align-items-center justify-content-between">
                <div className="title">
                  <i className="lni lni-protection"></i>
                  <span>Privacy</span>
                </div>
                <div className="data-content">
                  <Link className="pl-4" to="/privacy">
                    <i className="lni lni-chevron-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {isAuthenticated && (
            <div className="card settings-card">
              <div className="card-body">
                {/* Single Settings */}
                <div className="single-settings d-flex align-items-center justify-content-between">
                  <div className="title">
                    <i className="lni lni-lock"></i>
                    <span>
                      Change Password
                    </span>
                  </div>
                  <div className="data-content">
                    <Link to="/changepassword">
                      Change
                      <i className="lni lni-chevron-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default More;
