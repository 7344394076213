import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import moment from 'moment';

const Form = () => {
  const { tournamentId } = useParams();
  const [formData, setFormData] = useState({
    Name: '', // Updated to match the field name in Strapi
    DOB: '',
    Email: '',
    Mobile: '',
    Gender: '',
    AgeCategory: '',
    EventRange: '',
    Address: '',
    District: 'Chengalpattu', // Set default value for district
    State: 'Tamil Nadu', // Set default value for state
    RegistrationType: 'Individual', // Default value for registration type
    amount: '0', // Default value for amount
    OrderID: 'no', // Default value for order ID
    PaymentID: 'no', // Default value for payment ID
    PaymentStatus: '0', // Default value for payment status
    PaymentGateway: 'Free', // Default value for payment gateway
    event: tournamentId, // Set the tournament ID as event
    EntryStatus: 'Pending' // Default status for the entry
  });

  const [ageCategories, setAgeCategories] = useState([]);
  const [filteredEventRanges, setFilteredEventRanges] = useState([]);
  const [eventName, setEventName] = useState(''); // State to store event name

  useEffect(() => {
    if (tournamentId) {
      const fetchEventDetails = async () => {
        try {
          const response = await axios.get(`https://strapi.sportingindia.com/api/events/${tournamentId}?populate=*`);
          console.log('Raw API response:', response.data);

          const event = response.data.data.attributes;
          setEventName(event.Name); // Set the event name

          setAgeCategories(event.age_categories.data.map(category => ({
            id: category.id,
            name: category.attributes.name
          })));
        } catch (error) {
          console.error('Error fetching event details:', error);
        }
      };

      fetchEventDetails();
    }
  }, [tournamentId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = { ...formData, [name]: value };

    setFormData(updatedFormData);

    if (name === 'DOB' || name === 'Gender') {
      updateAgeCategory(updatedFormData);
    }
  };

  const updateAgeCategory = (formData) => {
    const age = calculateAge(formData.DOB);
    const ageCategory = determineAgeCategory(age, formData.Gender);

    console.log(`DOB: ${formData.DOB}, Age: ${age}, Gender: ${formData.Gender}, Age Category: ${ageCategory}`);

    setFormData((prevData) => ({
      ...prevData,
      AgeCategory: ageCategory
    }));
    filterEventRanges(ageCategory);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://strapi.sportingindia.com/api/evententries', {
        data: formData
      });
      alert('Form submitted successfully');
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const calculateAge = (dob) => {
    if (!dob) return 0;
    const birthDate = moment(dob, 'YYYY-MM-DD');
    const age = moment().diff(birthDate, 'years');
    return age;
  };

  const determineAgeCategory = (age, gender) => {
    if (gender === 'Male') {
      if (age < 10) return 'U10 Boys';
      if (age < 12) return 'U12 Boys';
      if (age < 14) return 'U14 Boys';
      if (age < 16) return 'U16 Boys';
      if (age < 18) return 'U18 Boys';
      if (age >= 50 && age < 60) return '50 - 60 Men';
      if (age >= 35 && age < 50) return '35 - 50 Men';
      if (age >= 80) return '80+ Men';
      if (age >= 18 && age < 35) return '18+ to 35 Men';
      return 'Unknown';
    } else if (gender === 'Female') {
      if (age < 10) return 'U10 Girls';
      if (age < 12) return 'U12 Girls';
      if (age < 14) return 'U14 Girls';
      if (age < 16) return 'U16 Girls';
      if (age < 18) return 'U18 Girls';
      if (age >= 60 && age < 80) return '60+ Women';
      if (age >= 35 && age < 60) return '35 - 60 Women';
      if (age >= 18 && age < 35) return '18+ to 35 Women';
      return 'Unknown';
    }
    return 'Unknown';
  };

  const filterEventRanges = (ageCategory) => {
    const ranges = {
      'U10 Boys': ['3 Km'],
      'U10 Girls': ['3 Km'],
      'U12 Boys': ['3 Km'],
      'U12 Girls': ['3 Km'],
      'U14 Boys': ['5 Km'],
      'U14 Girls': ['3 Km'],
      'U16 Boys': ['8 Km'],
      'U16 Girls': ['5 Km'],
      'U18 Boys': ['10 Km'],
      'U18 Girls': ['5 Km', '8 Km', '10 Km'],
      '35 - 50 Men': ['10 Km'],
      '50 - 60 Men': ['8 Km'],
      '35 - 60 Women': ['5 Km'],
      '60+ Women': ['3 Km'],
      '80+ Men': ['3 Km'],
      '18+ to 35 Men': ['12 Km'],
      '18+ to 35 Women': ['12 Km']
    };

    const filteredRanges = ranges[ageCategory] || [];
    console.log(`Filtered ranges for age category "${ageCategory}":`, filteredRanges);

    setFilteredEventRanges(filteredRanges.map(range => ({ id: range, name: range })));
  };

  return (
    <div className="page-content-wrapper">
      <div className="element-wrapper">
        <div className="container">
          <h6 className="mb-3 newsten-title text-center font-weight-bold">{eventName}</h6>
        </div>

        <div className="container">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="Name">Name</label>
              <input className="form-control" id="Name" type="text" name="Name" value={formData.Name} onChange={handleChange} placeholder="Your Name" required />
            </div>

            <div className="form-group">
              <label htmlFor="DOB">Date of Birth</label>
              <input className="form-control" id="DOB" type="date" name="DOB" value={formData.DOB} onChange={handleChange} required />
            </div>

            <div className="form-group">
              <label htmlFor="Email">Email</label>
              <input className="form-control" id="Email" type="email" name="Email" value={formData.Email} onChange={handleChange} placeholder="Email Address" required />
            </div>

            <div className="form-group">
              <label htmlFor="Mobile">Mobile</label>
              <input className="form-control" id="Mobile" type="text" name="Mobile" value={formData.Mobile} onChange={handleChange} placeholder="Mobile Number" required />
            </div>

            <div className="form-group">
              <label htmlFor="Gender">Gender</label>
              <select className="form-control custom-select" id="Gender" name="Gender" value={formData.Gender} onChange={handleChange} required>
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="AgeCategory">Age Category</label>
              <input className="form-control" id="AgeCategory" type="text" name="AgeCategory" value={formData.AgeCategory} readOnly />
            </div>

            <div className="form-group">
              <label htmlFor="EventRange">Event Range</label>
              <select className="form-control custom-select" id="EventRange" name="EventRange" value={formData.EventRange} onChange={handleChange} required>
                <option value="">Select Event Range</option>
                {filteredEventRanges.map(range => (
                  <option key={range.id} value={range.name}>{range.name}</option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="Address">Address</label>
              <input className="form-control" id="Address" type="text" name="Address" value={formData.Address} onChange={handleChange} placeholder="Address" required />
            </div>

            <div className="form-group">
              <label htmlFor="District">District</label>
              <input className="form-control" id="District" type="text" name="District" value={formData.District} readOnly />
            </div>

            <div className="form-group">
              <label htmlFor="State">State</label>
              <input className="form-control" id="State" type="text" name="State" value={formData.State} readOnly />
            </div>

            <button className="btn btn-primary btn-lg w-100" type="submit">Book Now</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Form;
