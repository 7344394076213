import React, { useEffect, useState } from 'react';
import axios from '../utils/axiosConfig';
import { Link } from 'react-router-dom';

const CategoryCard = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    axios.get('/categories?populate=image')
      .then(response => {
        setCategories(response.data.data);
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
      });
  }, []);

  return (
    <div className="page-content-wrapper">
        
    <div class="all-catagory-wrapper">
      <div class="container">
        <h5 class="mb-3 newsten-title">All Catagory</h5>
      </div>

      <div class="container">
        <div class="row">
        {categories.map(category => (
            <div class="col-6 col-sm-4" key={category.id}>
            <div class="card catagory-card mb-3">
              <Link to={`/category/${category.id}`}>
                <img src={`https://strapi.sportingindia.com${category.attributes.image.data.attributes.formats.small.url}`} alt="" />
                <h6>{category.attributes.Title}</h6>
              </Link>
            </div>
          </div>
            ))}
        </div>
      </div>
    </div>
      
    </div>
  );
};

export default CategoryCard;
