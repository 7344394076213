import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';

const EventDetails = ({ user }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await axios.get(`https://strapi.sportingindia.com/api/events/${id}?populate=*`);
        setEvent(response.data.data);
      } catch (error) {
        console.error('Error fetching event:', error);
      }
    };

    fetchEvent();
  }, [id]);

  if (!event) {
    return <div>Loading...</div>;
  }

  const { attributes } = event;
  const { Name, startdate, enddate, location, information, organizedby, event_category, Banner, Thumbnail, PaymentMode } = attributes;

  const bannerUrl = Banner?.data?.attributes?.url;
  const thumbnailUrl = Thumbnail?.data?.attributes?.url;
  const eventCategory = event_category?.data?.attributes?.Name || 'Unknown Category';

  const formatDate = (date) => moment(date).format('DD-MM-YYYY hh:mm A');

  const handleBookNow = () => {
    if (user) {
      navigate(`/register/${id}`);
    } else {
      navigate('/login');
    }
  };

  return (
    <div className="page-content-wrapper">
      <div id="scrollIndicator"></div>
      
      <div className="single-blog-thumbnail">
        <img className="w-100" src={`https://strapi.sportingindia.com${bannerUrl}`} alt={Name} />
      </div>

      <div className="single-blog-infos" style={{ paddingLeft: '0rem' }}>
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            <div className="post-content-wraps">
              <button className="post-catagory d-inline-block mb-2 btn btn-link" onClick={(e) => e.preventDefault()}>{eventCategory}</button>
              <h5 className="mb-2">{Name}</h5>
              <div className="post-meta">
                <button className="post-date btn btn-link" onClick={(e) => e.preventDefault()}>{formatDate(startdate)}</button>
              </div>
            </div>
            <button className="btn btn-sm btn-success text-white" onClick={handleBookNow}>Book Now</button>
          </div>
          <div className="event-details-highlight mt-3">
            <p><strong>Venue:</strong> {location}</p>
            <p><strong>Start Date & Time:</strong> {formatDate(startdate)}</p>
            <p><strong>End Date & Time:</strong> {formatDate(enddate)}</p>
            <p><strong>Payment:</strong> {PaymentMode}</p>
          </div>
        </div>
      </div>

      <div className="blog-description">
        <div className="container">
          {information.map((info, index) => (
            <p key={index}>{info.children.map(child => child.text).join(' ')}</p>
          ))}
        </div>
      </div>

      <div className="profile-content-wrapper">
        <div className="container">
          <div className="user-meta-data d-flex align-items-center">
            <div className="user-thumbnail">
              <img src={`https://strapi.sportingindia.com${thumbnailUrl}`} alt="Organizer" />
            </div>
            <div className="user-content">
              <h6>{organizedby}</h6>
              <p>Organizer</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventDetails;
