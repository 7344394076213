import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const EventsList = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get('https://strapi.sportingindia.com/api/events?populate=*');
        setEvents(response.data.data);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchEvents();
  }, []);

  return (
    <div className="page-content-wrapper">
      <div className="for-you-news-wrapper">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="mb-0 newsten-title">List of Events</h5>
            <p className="mb-0 line-height-1">{events.length} Events</p>
          </div>
        </div>
        <div className="container">
          <div className="row">
            {events.map((event) => {
              const eventCategory = event.attributes.event_category.data.attributes.Name || 'Unknown Category';
              const thumbnail = event.attributes.Thumbnail?.data?.attributes?.formats?.small?.url || 'img/bg-img/18.jpg';
              return (
                <div className="col-6 col-md-4" key={event.id}>
                  <div className="single-recommended-post mt-3">
                    <div className="post-thumbnail">
                      <img src={`https://strapi.sportingindia.com${thumbnail}`} alt="" width="452" height="607" />
                    </div>
                    <div className="post-content">
                      <button className="post-catagory btn btn-link" onClick={(e) => e.preventDefault()}>{eventCategory}</button>
                      <Link className="post-title" to={`/event/${event.id}`}>{event.attributes.Name}</Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventsList;
