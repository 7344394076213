import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from '../assets/logo.png';

const Header = ({ user }) => {
  const [isSidenavOpen, setIsSidenavOpen] = useState(false);
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [categoryCount, setCategoryCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await axios.get('https://api.ipgeolocation.io/ipgeo?apiKey=d0a04b02704f4905a8a174601a97fbbe');
        const data = response.data;
        setCity(data.city);
        setCountry(data.country_name);
      } catch (error) {
        console.error('Error fetching location data', error);
      }
    };

    fetchLocation();
  }, []);

  useEffect(() => {
    setIsAuthenticated(!!user);
  }, [user]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('https://strapi.sportingindia.com/api/categories?populate=image');
        const data = response.data;
        setCategoryCount(data.data.length);
      } catch (error) {
        console.error('Error fetching categories', error);
      }
    };

    fetchCategories();
  }, []);

  const toggleSidenav = () => {
    setIsSidenavOpen(!isSidenavOpen);
  };

  const closeSidenav = () => {
    setIsSidenavOpen(false);
  };

  const handleLogout = () => {
    // Clear the JWT token
    localStorage.removeItem('jwt');
    // Redirect to the login page
    navigate('/login');
    // Optionally, refresh the page to update the header state
    window.location.reload();
  };

  return (
    <header className="header">
      <div className="header-area" id="headerArea">
        <div className="container h-100 d-flex align-items-center justify-content-between">
          <div className="navbar--toggler" id="newstenNavbarToggler" onClick={toggleSidenav}>
            <span></span><span></span><span></span><span></span>
          </div>
          <div className="logo-wrapper">
            <Link to="/">
              <img src={logo} alt="Logo" className="" />
            </Link>
          </div>
          <div className="search-form">
            <Link to="/search">
              <i className="fa fa-search"></i>
            </Link>
          </div>
        </div>
      </div>

      <div className={`sidenav-black-overlay ${isSidenavOpen ? 'active' : ''}`} onClick={closeSidenav}></div>

      <div className={`sidenav-wrapper ${isSidenavOpen ? 'nav-active' : ''}`} id="sidenavWrapper">
        <div className="time-date-weather-wrapper text-center py-5" style={{ backgroundImage: "url('img/bg-img/1.jpg')" }}>
          <div className="weather-update mb-4">
            <div className="icon lni lni-cloudy-sun"></div>
            <h4 className="mb-1">{city} <br /> {country}</h4>
          </div>
          <div className="time-date">
            <div id="dashboardDate"></div>
            <div className="running-time d-flex justify-content-center">
              <div id="hours"></div><span>:</span>
              <div id="min"></div><span>:</span>
              <div id="sec"></div>
            </div>
            <div className="auth-buttons mt-3">
              {isAuthenticated ? (
                <button onClick={handleLogout} className="btn btn-link"><i className="lni lni-power-switch"></i> Log Out</button>
              ) : (
                <Link to="/login" onClick={closeSidenav} className="custom-login-link"><i className="lni lni-power-switch"></i> Log In</Link>
              )}
            </div>
          </div>
        </div>

        <ul className="sidenav-nav">
          {/* <li>
            <Link to="/live" onClick={closeSidenav}><i className="lni lni-play"></i>Live<span className="red-circle ml-2 flashing-effect"></span></Link>
          </li> */}
          {isAuthenticated && (
            <li>
              <Link to="/profile" onClick={closeSidenav}><i className="lni lni-user"></i>My Profile</Link>
            </li>
          )}
          <li>
            <Link to="/category" onClick={closeSidenav}><i className="lni lni-grid-alt"></i>All Category <span className="ml-2 badge badge-warning">{categoryCount}+</span></Link>
          </li>
          <li>
            <Link to="/more" onClick={closeSidenav}><i className="lni lni-cog"></i>Settings</Link>
          </li>
        </ul>

        <div className="go-home-btn" id="goHomeBtn" onClick={closeSidenav}>
          <i className="lni lni-arrow-left"></i>
        </div>
      </div>
    </header>
  );
};

export default Header;
