import React, { useState } from 'react';
import axios from '../utils/axiosConfig';
import { useNavigate,Link } from 'react-router-dom';

const Login = () => {
  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const payload = { identifier, password };
      console.log('Login payload:', payload);  // Log the payload
      const response = await axios.post('/auth/local', payload);
      
      // Save the JWT to localStorage or use a state management library
      localStorage.setItem('jwt', response.data.jwt);
      // Redirect to the home page
      navigate('/');
      // Optionally, refresh the page to update the header state
      window.location.reload();
    } catch (error) {
      console.error('An error occurred:', error.response);
      if (error.response && error.response.data && error.response.data.error) {
        setError(error.response.data.error.message);
      } else {
        setError('An error occurred. Please try again.');
      }
    }
  };

  return (
    <div className="login-wrapper d-flex align-items-center justify-content-center">
      {/* Shape */}
      <div className="login-shape">
        <img src="img/core-img/login.png" alt="Login Shape" />
      </div>
      <div className="login-shape2">
        <img src="img/core-img/login2.png" alt="Login Shape 2" />
      </div>

      <div className="container">
        {/* Login Text */}
        <div className="login-text text-center">
          <img className="login-img" src="img/bg-img/12.png" alt="Login" />
          <h3 className="mb-0">Welcome Back!</h3>
          {/* Shapes */}
          <div className="bg-shapes">
            <div className="shape1"></div>
            <div className="shape2"></div>
            <div className="shape3"></div>
            <div className="shape4"></div>
            <div className="shape5"></div>
            <div className="shape6"></div>
            <div className="shape7"></div>
            <div className="shape8"></div>
          </div>
        </div>

        {/* Login Form */}
        <div className="register-form mt-5 px-3">
          <form onSubmit={handleLogin}>
            <div className="form-group text-left mb-4">
              <label htmlFor="identifier">
                <i className="lni lni-user"></i>
              </label>
              <input
                className="form-control"
                id="identifier"
                type="text"
                name="identifier"
                placeholder="Username or email"
                value={identifier}
                onChange={(e) => setIdentifier(e.target.value)}
              />
            </div>

            <div className="form-group text-left mb-4">
              <label htmlFor="password">
                <i className="lni lni-lock"></i>
              </label>
              <input
                className="form-control"
                id="password"
                type="password"
                name="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            {error && <p className="error-message" style={{ color: 'red', fontWeight: 'bold', marginTop: '-1.5rem', marginLeft: '20px' }}>{error}</p>}

            <button className="btn btn-primary btn-lg w-100" type="submit">Login</button>
          </form>
        </div>

        {/* Login Meta */}
        <div className="login-meta-data text-center">
          <a className="forgot-password d-block mt-3 mb-1" href="/forget-password">Forgot Password?</a>
          <p className="mb-0">
            Don't have an account?<Link className="ml-2" to="/userregister">Register</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
