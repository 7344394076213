// src/utils/axiosConfig.js
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://strapi.sportingindia.com/api',
  // You can also set default headers here
  // headers: {
  //   'Content-Type': 'application/json',
  // },
});

export default axiosInstance;
