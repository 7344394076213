import React from 'react';
import { Link } from 'react-router-dom';
import Avatar from './Avatar';

const Profile = ({ user }) => {
  if (!user) {
    return <div>Loading...</div>; // or any loading indicator
  }

  return (
    <div className="page-content-wrapper">
      {/* Profile Content Wrapper */}
      <div className="profile-content-wrapper">
        {/* Settings Option */}
        <div className="profile-settings-option">
          <Link to="#">
            <i className="lni lni-cog"></i>
          </Link>
        </div>
        <div className="container">
          <div className="user-meta-data d-flex align-items-center">
            <div className="user-thumbnail">
              <Avatar name={user.name} />
            </div>
            <div className="user-content">
              <h6>{user.name}</h6>
              <p>User</p>
              
            </div>
          </div>
        </div>
      </div>

      {/* User All Article */}
      <div className="user-all-article-wrapper">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            <h6 className="mb-3 newsten-title">My Bookmark Articles</h6>
            <h6 className="mb-3 line-height-1">432 Posts</h6>
          </div>
        </div>

        <div className="container">
          {/* Single News Post */}
          <div className="single-news-post d-flex align-items-center bg-gray">
            <div className="post-thumbnail">
              <div className="video-icon">
                <i className="lni lni-play"></i>
              </div>
              <img src="img/bg-img/23.jpg" alt="News" />
            </div>
            <div className="post-content">
              <Link className="post-title" to="/single">The ship of the desert reducing rapidly in the world</Link>
              <div className="post-meta d-flex align-items-center">
                <Link to="#">Camel</Link>
                <Link to="#">1,625 views</Link>
              </div>
            </div>
          </div>

          {/* Single News Post */}
          <div className="single-news-post d-flex align-items-center bg-gray">
            <div className="post-thumbnail">
              <div className="video-icon">
                <i className="lni lni-play"></i>
              </div>
              <img src="img/bg-img/24.jpg" alt="News" />
            </div>
            <div className="post-content">
              <Link className="post-title" to="/single">Why you will eat apple & banana every day</Link>
              <div className="post-meta d-flex align-items-center">
                <Link to="#">Habits</Link>
                <Link to="#">1,112 views</Link>
              </div>
            </div>
          </div>

          {/* Single News Post */}
          <div className="single-news-post d-flex align-items-center bg-gray">
            <div className="post-thumbnail">
              <img src="img/bg-img/25.jpg" alt="News" />
            </div>
            <div className="post-content">
              <Link className="post-title" to="/single">Satellite now producing more waste on the planet</Link>
              <div className="post-meta d-flex align-items-center">
                <Link to="#">Planet</Link>
                <Link to="#">1,987 views</Link>
              </div>
            </div>
          </div>

          {/* Single News Post */}
          <div className="single-news-post d-flex align-items-center bg-gray">
            <div className="post-thumbnail">
              <img src="img/bg-img/26.jpg" alt="News" />
            </div>
            <div className="post-content">
              <Link className="post-title" to="/single">Balcony gardening is a new trend of big cities</Link>
              <div className="post-meta d-flex align-items-center">
                <Link to="#">Mayaj</Link>
                <Link to="#">589 views</Link>
              </div>
            </div>
          </div>

          {/* Single News Post */}
          <div className="single-news-post d-flex align-items-center bg-gray">
            <div className="post-thumbnail">
              <img src="img/bg-img/27.jpg" alt="News" />
            </div>
            <div className="post-content">
              <Link className="post-title" to="/single">The world is becoming polluted very fast and lives are being destroyed</Link>
              <div className="post-meta d-flex align-items-center">
                <Link to="#">Nazrul</Link>
                <Link to="#">2,789 views</Link>
              </div>
            </div>
          </div>

          {/* Single News Post */}
          <div className="single-news-post d-flex align-items-center bg-gray">
            <div className="post-thumbnail">
              <img src="img/bg-img/28.jpg" alt="News" />
            </div>
            <div className="post-content">
              <Link className="post-title" to="/single">Sea is polluted in a terrible way by human garbage</Link>
              <div className="post-meta d-flex align-items-center">
                <Link to="#">Suha</Link>
                <Link to="#">3,785 views</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
