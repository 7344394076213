import React, { useState } from 'react';
import axios from '../utils/axiosConfig';
import { Link, useNavigate } from 'react-router-dom';

const Register = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/auth/local/register', {
        username,
        email,
        password,
        name
      });
      
      // Save the JWT to localStorage or use a state management library
      localStorage.setItem('jwt', response.data.jwt);
      // Redirect to the home page
      navigate('/');
      // Optionally, refresh the page to update the header state
      window.location.reload();
    } catch (error) {
      console.error('An error occurred:', error.response);
      setError(error.response.data.message[0].messages[0].message);
    }
  };

  return (
    <div class="login-wrapper d-flex align-items-center justify-content-center">
    {/* <!-- Shape--> */}
    <div class="login-shape">
      <img src="img/core-img/login.png" alt="" />
    </div>

    <div class="login-shape2">
      <img src="img/core-img/login2.png" alt="" />
    </div>

    <div class="container">
      {/* <!-- Login Text--> */}
      <div class="login-text text-center">
        <img class="login-img" src="img/bg-img/12.png" alt="" />
        <h3 class="mb-0">Welcome, Register Now!</h3>

        {/* <!-- Shapes--> */}
        <div class="bg-shapes">
          <div class="shape1"></div>
          <div class="shape2"></div>
          <div class="shape3"></div>
          <div class="shape4"></div>
          <div class="shape5"></div>
          <div class="shape6"></div>
          <div class="shape7"></div>
          <div class="shape8"></div>
        </div>
      </div>
    
        <div className="register-form mt-5 px-3">
          <form onSubmit={handleRegister}>
            <div className="form-group text-left mb-4">
              <label htmlFor="name">
                <i className="lni lni-user"></i>
              </label>
              <input
                className="form-control"
                id="name"
                type="text"
                name="name"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group text-left mb-4">
              <label htmlFor="username">
                <i className="lni lni-user"></i>
              </label>
              <input
                className="form-control"
                id="username"
                type="text"
                name="username"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="form-group text-left mb-4">
              <label htmlFor="email">
                <i className="lni lni-envelope"></i>
              </label>
              <input
                className="form-control"
                id="email"
                type="email"
                name="email"
                placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group text-left mb-4">
              <label htmlFor="password">
                <i className="lni lni-lock"></i>
              </label>
              <input
                className="form-control"
                id="password"
                type="password"
                name="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button className="btn btn-primary btn-lg w-100">Register Now</button>
          </form>
          {error && <div className="alert alert-danger mt-4">{error}</div>}
        </div>
     <div class="login-meta-data text-center">
     <p class="mt-3 mb-0">Already have an account?<Link class="ml-2" to="/login">Login</Link></p>
   </div>
 </div>
</div>
  );
};

export default Register;
