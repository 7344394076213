// src/components/DateTime.js
import React, { useState, useEffect } from 'react';
// import './DateTime.css';

const DateTime = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  return (
    <div className="date-time">
      {formatDate(currentTime)}
    </div>
  );
};

export default DateTime;
