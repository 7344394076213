import React from 'react';
// import './About.css';

const About = () => {
  return (
    <div className="about-page">
      <h1>About Us</h1>
      <p>This is the about page content.</p>
    </div>
  );
};

export default About;
