import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from '../utils/axiosConfig';
import moment from 'moment'; // Import moment for date formatting
import '../assets/css/style.css'; // Ensure you import your CSS file

const TrendingNews = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    axios.get('/sportsnews?populate=*')
      .then(response => {
        const data = response.data.data.map(item => ({
          id: item.id,
          title: item.attributes.Title,
          description: item.attributes.description,
          author: item.attributes.author,
          createdAt: item.attributes.createdAt,
          updatedAt: item.attributes.updatedAt,
          publishedAt: item.attributes.publishedAt,
          imgSrc: item.attributes.image?.data?.attributes?.formats?.large?.url 
                    ? `https://strapi.sportingindia.com${item.attributes.image.data.attributes.formats.large.url}` 
                    : 'default-image-url.jpg', // Provide a default image URL or path
          category: item.attributes.category?.data?.attributes?.Title || 'Uncategorized',
          categoryLink: `/category/${item.attributes.category?.data?.id}` || '/category',
          link: `/news/${item.id}`, // Adjusted for dynamic routing
        }));
        setPosts(data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    <div className="trending-news-wrapper">
      <div className="container">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <h5 className="mb-0 pl-1 newsten-title">Trending</h5>
          <Link to="/" className="btn btn-primary btn-sm">View All</Link>
        </div>
      </div>
      <div className="container">
        {posts.map(post => (
          <div key={post.id} className="single-trending-post d-flex">
            <div className="post-thumbnail">
              <img src={post.imgSrc} alt={post.title} />
            </div>
            <div className="post-content">
              <Link className="post-title" to={post.link}>{post.title}</Link>
              <div className="post-meta d-flex align-items-center">
                <Link to={post.categoryLink}>{post.category}</Link>
                <span>{moment(post.publishedAt).format('DD MMM YYYY')}</span> {/* Format the date */}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrendingNews;
