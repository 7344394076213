import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';

const Catagories = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('https://strapi.sportingindia.com/api/categories?populate=image');
        setCategories(response.data.data);
        setLoading(false);
        
      } catch (error) {
        setError('Error fetching categories');
        setLoading(false);
      }
    };
    fetchCategories();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="top-catagories-wrapper">
      <div className="bg-shapes">
        <div className="shape1"></div>
        <div className="shape2"></div>
        <div className="shape3"></div>
        <div className="shape4"></div>
        <div className="shape5"></div>
      </div>
      <h6 className="mb-3 catagory-title">Top Categories</h6>
      <div className="container">
        <OwlCarousel className="catagory-slides owl-carousel" loop margin={5} items={3}>
          {categories.map((category) => {
            let imageUrl = 'default-image-url.jpg';
            if (category.attributes.image?.data?.attributes?.formats?.medium?.url) {
              imageUrl = `https://strapi.sportingindia.com${category.attributes.image.data.attributes.formats.medium.url}`;
            } else if (category.attributes.image?.data?.attributes?.formats?.large?.url) {
              imageUrl = `https://strapi.sportingindia.com${category.attributes.image.data.attributes.formats.large.url}`;
            } else if (category.attributes.image?.data?.attributes?.formats?.small?.url) {
              imageUrl = `https://strapi.sportingindia.com${category.attributes.image.data.attributes.formats.small.url}`;
            }

            return (
              <div key={category.id} className="card catagory-card">
                <Link to={`/category/${category.id}`}>
                  <img src={imageUrl} alt={category.attributes.Title} />
                  <h6>{category.attributes.Title}</h6>
                </Link>
              </div>
            );
          })}
        </OwlCarousel>
      </div>
    </div>
  );
};

export default Catagories;
