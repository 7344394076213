import React from 'react';

const getInitials = (name) => {
  const initials = name.split(' ').map(word => word[0]).join('');
  return initials.toUpperCase();
};

const Avatar = ({ name }) => {
  const initials = getInitials(name);
  return (
    <div className="avatar" style={styles.avatar}>
      {initials}
    </div>
  );
};

const styles = {
  avatar: {
    width: '100px',
    height: '100px',
    borderRadius: '1rem',
    backgroundColor: '#007bff',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  }
};

export default Avatar;
