import React, { useState } from 'react';
import axios from 'axios';

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    setSuccess(null);

    if (password !== passwordConfirmation) {
      setError('New passwords do not match');
      return;
    }

    try {
      await axios.post('https://strapi.sportingindia.com/api/auth/change-password', {
        currentPassword,
        password,
        passwordConfirmation,
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`, // Ensure the user is authenticated
        },
      });
      setSuccess('Password updated successfully');
      // Clear fields after success
      setCurrentPassword('');
      setPassword('');
      setPasswordConfirmation('');
    } catch (error) {
      setError(error.response?.data?.message || 'Something went wrong');
    }
  };

  return (
    <div className="page-content-wrapper">
      <div className="container">
        <div className="card change-password-card">
          <div className="card-body">
            {error && <div className="alert alert-danger">{error}</div>}
            {success && <div className="alert alert-success">{success}</div>}
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <span>Current Password</span>
                <input
                  className="form-control"
                  type="password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <span>New Password</span>
                <input
                  className="form-control"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <span>Repeat New Password</span>
                <input
                  className="form-control"
                  type="password"
                  value={passwordConfirmation}
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                  required
                />
              </div>
              <button className="btn btn-success w-100" type="submit">Update Password</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
