import React, { useState, useEffect } from 'react';
import axios from '../utils/axiosConfig'; // Adjust the import according to your file structure
import { Link, useParams } from 'react-router-dom';

const CategoryPage = () => {
  const { catId: categoryId } = useParams();
  const [category, setCategory] = useState(null);
  const [news, setNews] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const response = await axios.get(`/categories/${categoryId}`);
        setCategory(response.data.data);
      } catch (error) {
        console.error('Error fetching category:', error);
      }
    };

    const fetchNews = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`/sportsnews?filters[category][id][$eq]=${categoryId}&populate=*&pagination[page]=${page}&pagination[pageSize]=10`);
        const newNews = response.data.data;

        // Filter out duplicate news items by ID
        setNews((prevNews) => {
          const allNews = [...prevNews, ...newNews];
          const uniqueNews = Array.from(new Set(allNews.map((item) => item.id))).map(
            (id) => allNews.find((item) => item.id === id)
          );
          return uniqueNews;
        });

        setHasMore(response.data.meta.pagination.page < response.data.meta.pagination.pageCount);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching news:', error);
        setLoading(false);
      }
    };

    fetchCategory();
    fetchNews();
  }, [categoryId, page]);

  const loadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <div className="page-content-wrapper">
      <div className="container">
        <h1>{category ? category.attributes.Title : 'Category'}</h1>
        <div className="trending-news-wrapper">
          {news.length === 0 && !loading && <p>No posts are available for this category.</p>}
          {news.map((article) => (
            <div key={article.id} className="single-trending-post d-flex">
              <div className="post-thumbnail">
                {article.attributes.image && article.attributes.image.data && (
                  <img src={`https://strapi.sportingindia.com${article.attributes.image.data.attributes.url}`} alt={article.attributes.Title} />
                )}
              </div>
              <div className="post-content">
                <Link className="post-title" to={`/news/${article.id}`}>{article.attributes.Title}</Link>
                <div className="post-meta d-flex align-items-center">
                  <Link to={`/category/${article.attributes.category.data.id}`}>{article.attributes.category.data.attributes.Title}</Link>
                  <span>{new Date(article.attributes.createdAt).toLocaleDateString()}</span>
                </div>
              </div>
            </div>
          ))}
          {loading && <p>Loading...</p>}
          {hasMore && !loading && (
            <div className="text-center mt-3">
              <button className="btn btn-primary" onClick={loadMore}>Load More</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CategoryPage;
