import React, { useEffect, useState } from 'react';
import DateTime from './DateTime';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';

const NewsToday = () => {
  const [news, setNews] = useState([]);

  useEffect(() => {
    fetch('https://strapi.sportingindia.com/api/sportsnews?populate=*')
      .then((response) => response.json())
      .then((data) => {
        setNews(data.data || []);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  return (
    <div className="news-today-wrapper">
      <div className="container">
        <div className="d-flex mb-3 align-items-center justify-content-between">
          <h5 className="mb-0 pl-1 newsten-title">News Today</h5>
          <DateTime />
        </div>
        {news.length > 0 && (
          <OwlCarousel className="hero-slides owl-carousel" loop margin={10} nav items={1}>
            {news.map((item) => {
              const imageUrl = item.attributes.image?.data?.attributes?.url
                ? `https://strapi.sportingindia.com${item.attributes.image.data.attributes.url}`
                : 'default-image-url.jpg'; // Provide a default image URL or path

              const categoryTitle = item.attributes.category?.data?.attributes?.Title || 'Uncategorized';
              const author = item.attributes.authors?.data[0]?.attributes?.author || 'Uncategorized';
              const publishedDate = new Date(item.attributes.publishedAt).toLocaleDateString();

              return (
                <div
                  key={item.id}
                  className="single-hero-slide"
                  style={{ backgroundImage: `url(${imageUrl})` }}
                >
                  <div className="background-shape">
                    <div className="circle2"></div>
                    <div className="circle3"></div>
                  </div>
                  <div className="slide-content h-100 d-flex align-items-end">
                    <div className="container-fluid mb-3">
                      <div className="video-icon"><i className="lni lni-play"></i></div>
                      {/* <button className="bookmark-post"><i className="lni lni-bookmark"></i></button> */}
                      <Link className="post-catagory" to="/category">
                        {categoryTitle}
                      </Link>
                      <Link className="post-title d-block" to={`/news/${item.id}`}>
                        {item.attributes.Title}
                      </Link>
                      <div className="post-meta d-flex align-items-center">
                        <Link to="#"><i className="mr-1 lni lni-user"></i>{author || 'Unknown'}</Link>
                        <span><i className="mr-1 lni lni-calendar"></i>{publishedDate}</span>
                        <span><i className="mr-1 lni lni-bar-chart"></i>4 min read</span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </OwlCarousel>
        )}
      </div>
    </div>
  );
};

export default NewsToday;
