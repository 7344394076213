import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/axiosConfig';
import NewsToday from './NewsToday';
import Catagories from './Categories';
import TrendingNews from './TrendingNews';

const HomePage = () => {
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await axios.get('/tags');
        setTags(response.data.data);
        setLoading(false);
      } catch (error) {
        setError('Error fetching tags');
        setLoading(false);
      }
    };

    const fetchNews = async () => {
      try {
        const response = await axios.get('/sportsnews?populate=*');
        // Assuming setNews is not used currently and can be removed
      } catch (error) {
        console.error('Error fetching news:', error);
      }
    };

    fetchTags();
    fetchNews();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  const colors = [
    'btn-primary',
    'btn-success',
    'btn-warning',
    'btn-danger',
    'btn-info',
    'btn-secondary',
    'btn-dark',
    'btn-light'
  ];

  const handleTagClick = (tagId) => {
    navigate(`/tag/${tagId}`);
  };

  return (
    <main>
      <div className="page-content-wrapper">
        <NewsToday />
        <Catagories />
        <TrendingNews />

        <div className="popular-tags-wrapper">
          <div className="container">
            <h5 className="mb-3 pl-2 newsten-title">Popular Tags</h5>
          </div>

          <div className="container">
            <div className="popular-tags-list">
              {tags.map((tag, index) => (
                <button key={tag.id} className={`btn btn-sm m-1 ${colors[index % colors.length]}`} type="button" onClick={() => handleTagClick(tag.id)}>
                  {`#${tag.attributes.tag}`}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default HomePage;
