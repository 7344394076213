import React, { useEffect, useState } from 'react';
import axios from '../utils/axiosConfig';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

const NewsDetailsComponent = () => {
  const { id } = useParams();
  const [news, setNews] = useState(null);
  const [relatedNews, setRelatedNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const BASE_URL = 'https://strapi.sportingindia.com'; // Replace this with your actual base URL

  useEffect(() => {
    const fetchNewsDetails = async () => {
      try {
        const response = await axios.get(`/sportsnews/${id}?populate=*`);
        const newsData = response.data.data;
        setNews(newsData);

        // Fetch related news based on category
        const categoryId = newsData.attributes.category?.data?.id;
        if (categoryId) {
          const relatedNewsResponse = await axios.get(`/sportsnews?filters[category][id][$eq]=${categoryId}&populate=*`);
          setRelatedNews(relatedNewsResponse.data.data);
        }
        
        setLoading(false);

        // Increment the view count
        await axios.put(`/sportnews/${id}/increment-view`);
      } catch (error) {
        console.error('Error fetching news details:', error);
        setError('Error fetching news details');
        setLoading(false);
      }
    };

    fetchNewsDetails();
  }, [id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  if (!news) return <div>No news data available</div>;

  const { Title, description, createdAt } = news.attributes;
  const image = BASE_URL + news.attributes.image?.data?.attributes?.formats?.large?.url;
  const category = news.attributes.category?.data?.attributes?.Title || "Uncategorized";
  const viewCount = news.attributes.viewCount || 0;
  const author = news.attributes.authors?.data?.[0]?.attributes?.author || "Unknown Author";

  return (
    <div className="page-content-wrapper">
      <ShareModal />
      <ScrollIndicator />
      <SingleBlogThumbnail image={image} />
      <SingleBlogInfo title={Title} date={createdAt} views={viewCount} category={category} />
      <BlogDescription content={description} />
      <PostAuthor author={author} />
      <RelatedPosts relatedNews={relatedNews} />
      
    </div>
  );
};

const ShareModal = () => (
    <div className="modal fade post-share-modal" id="postShareModal" tabIndex="-1" role="dialog" aria-labelledby="" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button className="close" type="button" data-dismiss="modal" aria-label="Close">
              <i className="lni lni-close"></i>
            </button>
            <h6 className="mb-3 pl-2">Share this post</h6>
            <div className="social-share-btn d-flex align-items-center flex-wrap">
              <Link className="btn-facebook" to="#"><i className="lni lni-facebook"></i></Link>
              <Link className="btn-twitter" to="#"><i className="lni lni-twitter-original"></i></Link>
              <Link className="btn-instagram" to="#"><i className="lni lni-instagram"></i></Link>
              <Link className="btn-whatsapp" to="#"><i className="lni lni-whatsapp"></i></Link>
              <Link className="btn-linkedin" to="#"><i className="lni lni-linkedin"></i></Link>
              <Link className="btn-tumblr" to="#"><i className="lni lni-tumblr"></i></Link>
              <Link className="btn-quora" to="#"><i className="lni lni-quora"></i></Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  

const ScrollIndicator = () => <div id="scrollIndicator"></div>;

const SingleBlogThumbnail = ({ image }) => (
  <div className="single-blog-thumbnail">
    <img className="w-100" src={image} alt="Blog" />
    <Link className="post-bookmark" to="#">
      <i className="lni lni-bookmark"></i>
    </Link>
  </div>
);

const SingleBlogInfo = ({ title, date, views, category }) => (
  <div className="single-blog-info">
    <div className="container">
      <div className="d-flex align-items-center">
        <PostLikeWrap />
        <PostContentWrap title={title} date={date} views={views} category={category} />
      </div>
    </div>
  </div>
);

const PostLikeWrap = () => (
  <div className="post-like-wrap">
    <Link className="post-love d-block" to="#">
      <i className="lni lni-heart"></i>
    </Link>
    <span className="d-block">368 Likes</span>
    <div className="line"></div>
    <Link className="post-share" to="#" data-toggle="modal" data-target="#postShareModal">
      <i className="fa fa-share-alt"></i>
    </Link>
    <span className="d-block">1,028</span>
  </div>
);

const PostContentWrap = ({ title, date, views, category }) => (
  <div className="post-content-wrap">
    <Link className="post-catagory d-inline-block mb-2" to="#">{category}</Link>
    <h5 className="mb-2">{title}</h5>
    <div className="post-meta">
      <Link className="post-date" to="#">{new Date(date).toLocaleDateString()}</Link>
      <Link className="post-views" to="#">{views} Views</Link>
    </div>
  </div>
);

const BlogDescription = ({ content }) => (
  <div className="blog-description">
    <div className="container">
      {content.map((paragraph, index) => (
        <p key={index}>{paragraph.children[0].text}</p>
      ))}
    </div>
  </div>
);

const PostAuthor = ({ author }) => {
    const authorInitials = author.split('').map(name => name[0]).join('').substring(0, 3).toUpperCase();
  
    return (
      <div className="profile-content-wrapper">
        
        <div className="container">
          <div className="user-meta-data d-flex align-items-center">
            <div className="user-thumbnail">
              <div className="author-placeholder" style={{ backgroundColor: '#007bff', color: '#fff', width: '50px', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', fontSize: '18px' }}>
                {authorInitials}
              </div>
            </div>
            <div className="user-content">
              <h6>{author}</h6>
              <p>Publisher</p>
              
            </div>
          </div>
        </div>
      </div>
    );
  };
  

const RelatedPosts = ({ relatedNews }) => (
  <div className="related-post-wrapper">
    <div className="container">
      <h6 className="mb-3 newsten-title">Related Posts</h6>
      {relatedNews.map(newsItem => (
        <TrendingPost
          key={newsItem.id}
          image={newsItem.attributes.image?.data?.attributes?.formats?.small?.url}
          title={newsItem.attributes.Title}
          category={newsItem.attributes.category?.data?.attributes?.Title}
          date={new Date(newsItem.attributes.createdAt).toLocaleDateString()}
        />
      ))}
    </div>
  </div>
);

const TrendingPost = ({ image, title, category, date, isVideo }) => (
  <div className="single-trending-post d-flex">
    <div className="post-thumbnail">
      {isVideo && <div className="video-icon"><i className="lni lni-play"></i></div>}
      <img src={`https://strapi.sportingindia.com${image}`} alt="Trending" className="trending-image" />
    </div>
    <div className="post-content">
      <Link className="post-title" to={`/news/${title}`}>{title}</Link>
      <div className="post-meta d-flex align-items-center">
        <Link to="/category">{category}</Link>
        <Link to="#">{date}</Link>
      </div>
    </div>
  </div>
);

export default NewsDetailsComponent;
